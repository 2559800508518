var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-overlay",
        {
          attrs: { center: "", show: _vm.isLoading, rounded: "sm" },
          scopedSlots: _vm._u([
            {
              key: "overlay",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "text-center mt-5" },
                    [
                      _c("base-icon", {
                        attrs: { name: "loading", width: "35", height: "35" },
                      }),
                      _vm._v(" "),
                      _c("p", { attrs: { id: "cancel-label" } }, [
                        _vm._v("Operazione in corso..."),
                      ]),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          !_vm.isLoading
            ? _c(
                "div",
                [
                  _c(
                    "b-form",
                    {
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                          return _vm.onSearch(_vm.filterName)
                        },
                      },
                    },
                    [
                      _c(
                        "b-card",
                        { staticClass: "filter" },
                        [
                          _c("b-row", [
                            _c(
                              "div",
                              { staticClass: "col-md-3" },
                              [
                                _c("base-select", {
                                  attrs: {
                                    name: "code",
                                    label: "Rifiutato",
                                    options: [
                                      { value: "Y", text: "Si" },
                                      { value: "N", text: "No" },
                                    ],
                                  },
                                  model: {
                                    value: _vm.filter.byAttribute.is_error,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.filter.byAttribute,
                                        "is_error",
                                        $$v
                                      )
                                    },
                                    expression: "filter.byAttribute.is_error",
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                staticClass:
                                  "form-group col-md-4 align-self-end",
                              },
                              [
                                _c(
                                  "b-button",
                                  {
                                    attrs: {
                                      type: "submit",
                                      variant: "lisaweb",
                                      size: "sm",
                                    },
                                  },
                                  [_vm._v("Cerca")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "b-button",
                                  {
                                    staticClass: "btn-reset",
                                    attrs: {
                                      type: "button",
                                      variant: "lisaweb",
                                      size: "sm",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.onClearFilter(_vm.filterName)
                                      },
                                    },
                                  },
                                  [_vm._v("Reset")]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("import-details", {
                    ref: _vm.tableRef,
                    attrs: {
                      fields: _vm.fields,
                      repository: _vm.repository,
                      resource: _vm.resource,
                      filterName: _vm.filterName,
                      filterOn: {
                        byImportLog: {
                          id: _vm.dbRowData.import_logs.length
                            ? _vm.dbRowData.import_logs[0].id
                            : 0,
                        },
                      },
                      noInnerWidth: "",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }