var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "mt-2" }, [
    _c(
      "div",
      { style: { width: !_vm.noInnerWidth ? _vm.width : "auto" } },
      [
        _c(
          "div",
          [
            _c("b-table", {
              attrs: {
                busy: _vm.isLoading,
                "head-variant": "light",
                "no-local-sorting": true,
                items: _vm.items,
                fields: _vm.getAllFields,
                "current-page": _vm.currentPage,
                "per-page": "0",
                "sort-by": _vm.sortBy,
                "sort-desc": _vm.sortDesc,
                "sort-direction": _vm.sortDirection,
                "sticky-header": _vm.height,
                "no-border-collapse": "",
                responsive: "",
                "show-empty": "",
                "empty-text": "Non ci sono informazioni da mostrare",
                small: "",
              },
              on: {
                "update:sortBy": function ($event) {
                  _vm.sortBy = $event
                },
                "update:sort-by": function ($event) {
                  _vm.sortBy = $event
                },
                "update:sortDesc": function ($event) {
                  _vm.sortDesc = $event
                },
                "update:sort-desc": function ($event) {
                  _vm.sortDesc = $event
                },
                "sort-changed": _vm.sortingChanged,
              },
              scopedSlots: _vm._u([
                {
                  key: "table-busy",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        { staticClass: "text-center my-2" },
                        [
                          _c("base-icon", {
                            attrs: {
                              name: "loading",
                              width: "35",
                              height: "35",
                            },
                          }),
                          _vm._v(" "),
                          _c("p", [_vm._v("Operazione in corso...")]),
                        ],
                        1
                      ),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "cell(insurer)",
                  fn: function (row) {
                    return [
                      _vm._v(
                        "\n          " +
                          _vm._s(
                            row.item.import_logs.length
                              ? `${row.item.import_logs[0].insurer.code}-${row.item.import_logs[0].insurer.title}`
                              : ""
                          ) +
                          "\n        "
                      ),
                    ]
                  },
                },
                {
                  key: "cell(detail)",
                  fn: function (row) {
                    return [
                      _c(
                        "div",
                        { staticClass: "action-buttons" },
                        [
                          _c(
                            "b-button",
                            {
                              staticClass: "mt-1 mr-1",
                              attrs: {
                                size: "sm",
                                variant: "lisaweb",
                                title: "Modale Dettagli",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.openModal(
                                    row.item,
                                    row.index,
                                    $event.target
                                  )
                                },
                              },
                            },
                            [_c("b-icon", { attrs: { icon: "eye" } })],
                            1
                          ),
                        ],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: "cell(actions)",
                  fn: function (row) {
                    return [
                      _c(
                        "div",
                        { staticClass: "action-buttons" },
                        [
                          _vm.actions.includes("infomodal")
                            ? _c(
                                "b-button",
                                {
                                  staticClass: "mt-1 mr-1",
                                  attrs: {
                                    size: "sm",
                                    variant: "lisaweb",
                                    title: "Modale Dettagli",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.openModal(
                                        row.item,
                                        row.index,
                                        $event.target
                                      )
                                    },
                                  },
                                },
                                [_c("b-icon", { attrs: { icon: "eye" } })],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.actions.includes("details")
                            ? _c(
                                "b-button",
                                {
                                  staticClass: "mt-1 mr-1",
                                  attrs: {
                                    size: "sm",
                                    variant: "lisaweb",
                                    title: row.detailsShowing
                                      ? "Chiudi dettagli"
                                      : "Apri dettagli",
                                  },
                                  on: { click: row.toggleDetails },
                                },
                                [
                                  row.detailsShowing
                                    ? _c("b-icon", {
                                        attrs: { icon: "chevron-up" },
                                      })
                                    : _c("b-icon", {
                                        attrs: { icon: "chevron-down" },
                                      }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.actions.includes("edit")
                            ? _c(
                                "b-button",
                                {
                                  staticClass: "mt-1 mr-1",
                                  attrs: {
                                    size: "sm",
                                    variant: "lisaweb",
                                    title: "Modifica",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onEdit(row.item.id)
                                    },
                                  },
                                },
                                [
                                  _c("b-icon", {
                                    attrs: { icon: "pencil-square" },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.actions.includes("destroy")
                            ? _c(
                                "b-button",
                                {
                                  staticClass: "mt-1 mr-1",
                                  attrs: {
                                    size: "sm",
                                    variant: "lisaweb",
                                    title: "Elimina",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.onDestroy(row.item.id)
                                    },
                                  },
                                },
                                [_c("b-icon", { attrs: { icon: "trash" } })],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ]
                  },
                },
                {
                  key: "row-details",
                  fn: function (row) {
                    return [
                      _c(
                        "b-card",
                        _vm._l(_vm.customized(row.item), function (value, key) {
                          return _c(
                            "b-row",
                            { key: key, staticClass: "mb-2" },
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass: "text-sm-right",
                                  attrs: { sm: "3" },
                                },
                                [
                                  _c("b", [
                                    _vm._v(
                                      _vm._s(_vm._f("capitalize")(key)) + ":"
                                    ),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c("b-col", [_vm._v(_vm._s(value))]),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ]
                  },
                },
              ]),
            }),
          ],
          1
        ),
        _vm._v(" "),
        _c("b-row", [
          _c("div", { staticClass: "show-text" }, [
            _c("p", [_vm._v("Mostra")]),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "group" },
            [
              _c(
                "b-form-group",
                {
                  attrs: {
                    "label-for": "per-page-select",
                    "label-cols-sm": "3",
                    "label-cols-md": "",
                    "label-cols-lg": "3",
                    "label-size": "sm",
                    md: "auto",
                  },
                },
                [
                  _c("b-form-select", {
                    staticStyle: { width: "68px" },
                    attrs: {
                      id: "per-page-select",
                      options: _vm.pageOptions,
                      size: "sm",
                      disabled: !_vm.items.length,
                    },
                    on: {
                      change: function ($event) {
                        return _vm.onPageChange(1)
                      },
                    },
                    model: {
                      value: _vm.perPage,
                      callback: function ($$v) {
                        _vm.perPage = $$v
                      },
                      expression: "perPage",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "page" }, [
            _c("p", [_vm._v("di " + _vm._s(_vm.totalItems) + " per pagina")]),
          ]),
          _vm._v(" "),
          _vm.totalItems
            ? _c(
                "div",
                { staticClass: "pagination" },
                [
                  _c("b-pagination", {
                    attrs: {
                      limit: "3",
                      "total-rows": _vm.totalItems,
                      "per-page": _vm.perPage,
                      size: "sm",
                    },
                    on: { change: _vm.onPageChange },
                    model: {
                      value: _vm.currentPage,
                      callback: function ($$v) {
                        _vm.currentPage = $$v
                      },
                      expression: "currentPage",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c(
          "b-modal",
          {
            attrs: {
              id: _vm.infomodalName,
              title: _vm.modalTitle,
              size: "xl",
              scrollable: "",
              "ok-only": "",
              "ok-variant": "lisaweb",
              "header-bg-variant": "lisaweb",
              "no-close-on-backdrop": "",
            },
            on: { hidden: _vm.onModalHidden },
          },
          [
            _c(
              "div",
              [
                _c(
                  "b-tabs",
                  {
                    attrs: { "content-class": "pt-1", lazy: "" },
                    model: {
                      value: _vm.tabIndex,
                      callback: function ($$v) {
                        _vm.tabIndex = $$v
                      },
                      expression: "tabIndex",
                    },
                  },
                  _vm._l(_vm.tabs, function (tab, index) {
                    return _c(
                      "b-tab",
                      {
                        key: index,
                        attrs: {
                          title: tab.text,
                          active: index === _vm.tabIndex,
                          "title-item-class": "lisaweb",
                        },
                        on: {
                          click: function ($event) {
                            return _vm.setTab(tab, index)
                          },
                        },
                      },
                      [
                        _c("button-group-tab"),
                        _vm._v(" "),
                        _c(
                          "keep-alive",
                          [
                            _vm.selected === tab.name
                              ? _c(_vm.selected, {
                                  tag: "component",
                                  attrs: {
                                    resourceId: _vm.modalResourceId,
                                    dbRowData: _vm.dbRowData,
                                  },
                                  on: { fetch: _vm.fetch },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  1
                ),
              ],
              1
            ),
          ]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }